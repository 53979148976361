import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/apiUrl";

import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import OrderStockModal from "../stock/OrderStockModal";

const CoffeeInventoryMain = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [openOrderStockModal, setOpenOrderStockModal] = useState(false);

  const [inventoryData, setInventoryData] = useState([]);

  const toggleOrderStockModal = () =>
    setOpenOrderStockModal(!openOrderStockModal);

  const getInventoryData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/store/stock/information`);

      dispatch(isLoading(false));
      setInventoryData(res.data.vendorsStocks?.inStock);
    } catch (error) {
      console.log("error=>", error);
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch("something went wrong", "error");
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getInventoryData();
  }, []);

  return (
    <>
      <Helmet>
        <title> STOCK | CHIPT </title>
      </Helmet>
      <Container>
        <Stack
          direction={"row"}
          mb={2.5}
          alignItems={"center"}
          mt={1}
          justifyContent={"space-between"}
          spacing={1}
        >
          <Typography variant="h3">Cups Stock </Typography>
          {/* <Avatar
            src="./assets/icons/coffeeCup.gif"
            sx={{ width: "30px" }}
            alt="kk"
          /> */}
          <Stack direction={"row"} spacing={1}>
            {/* <Button variant="contained">Update Stock</Button> */}
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              // onClick={toggleOrderStockModal}
              onClick={() => navigate("/inventory/place/order")}
            >
              Order Stock
            </Button>
          </Stack>
        </Stack>

        {/* <Stack
          mt={1}
          display={"flex"}
          justifyContent={"start"}
          // flexWrap={"wrap"}
          direction={"row"}
       
        > */}
        {inventoryData?.length > 0 ? (
          inventoryData?.map((cupModals, rowIndex) => (
            <>
              <Grid container key={rowIndex} spacing={2}>
                {cupModals?.sizes?.map((cupSizes, index) => (
                  <Grid item key={index} xs={12} sm={4} md={3} lg={4}>
                    <Card
                      bgColor="primary"
                      sx={{
                        minWidth: 200,
                        mt: 2,
                        bgcolor: index % 2 === 0 ? "#f5efeb" : "#7c573d",
                      }}
                    >
                      <CardContent>
                        <Stack direction={"row"} justifyContent={"center"}>
                          <Avatar
                            src="./assets/icons/coffeeCup.gif"
                            sx={{ width: "100px", height: "100px" }}
                          />
                        </Stack>
                        <Typography
                          variant="h2"
                          sx={{
                            color: index % 2 === 0 ? "#2d2017" : "white",
                          }}
                          //   component="div"
                          textAlign={"center"}
                        >
                          {cupSizes?.cupCapacity
                            ? cupSizes?.cupCapacity
                            : "250"}
                          <Typography
                            component={"span"}
                            sx={{
                              color: index % 2 === 0 ? "black" : "white",
                            }}
                          >
                            ml
                          </Typography>
                        </Typography>

                        <Typography
                          variant="h4"
                          sx={{
                            color: index % 2 === 0 ? "#2d2017" : "white",
                          }}
                          //   component="div"
                          textAlign={"center"}
                        >
                          {cupModals?.cupType}
                          <Typography
                            variant="body1"
                            component={"span"}
                            fontSize={"0.8rem"}
                            sx={{
                              fontWeight: 300,
                              color: index % 2 === 0 ? "black" : "white",
                            }}
                          >
                            {" ("}
                            {cupSizes?.cupSize}
                            {")"}
                          </Typography>
                        </Typography>

                        <Typography
                          sx={{
                            mb: 0.5,
                            color: index % 2 === 0 ? "black" : "white",
                            fontSize: "0.8rem",
                          }}
                          textAlign={"center"}
                        >
                          Cup Price:{" "}
                          <Typography fontSize="0.8rem" component={"span"}>
                            ${cupSizes?.cupPrice}
                          </Typography>
                        </Typography>
                        <Typography
                          //   sx={{ mb: 1 }}
                          textAlign={"center"}
                          color="error"
                          fontFamily={"monospace"}
                        >
                          Stock:{" "}
                          <Typography component={"span"}>
                            {cupSizes?.numberOfCups}{" "}
                          </Typography>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ))
        ) : (
          <>
            <Typography variant="body2" color={"error"} component={"p"}>
              You store is empty made orders to add ineventory ! , made request
              for stock orders . Thanks !
            </Typography>
            <Stack mt={2} alignItems={"center"}>
              <img
                src="./assets/icons/nodata.gif"
                alt="no data found"
                // width={"200px"}
                style={{ maxHeight: "280px" }}
              />
            </Stack>
          </>
        )}

        {/* </Stack> */}
      </Container>
      <OrderStockModal
        open={openOrderStockModal}
        handleClose={toggleOrderStockModal}
      />
    </>
  );
};

export default CoffeeInventoryMain;
